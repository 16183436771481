export const currData = (data) => async (dispatch) => {
	// const user = Auth.currentAuthenticatedUser();
	// console.log('',user)
	// console.log('getAuth :', getAuth);
	if (data) {
		dispatch({
			type: 'CURR_DATA',
			payload: data,
		});
	}
};
