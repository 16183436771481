export const sendUserSelectionDataToReactNative = (data) => {
	
    let finalData = {
        type: 'userSelectionData',
        data: data
    }
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify(finalData));
	} catch (err) {
		console.log('cameraAccessRequest err:', err);
	}
};
export const sendWebviewLoadedToReactNativ = () => {
	try {
		window.ReactNativeWebView.postMessage('webviewLoaded');
	} catch (err) {
		console.log('cameraAccessRequest err:', err);
	}
};
export const startAndEndTimeIndexToReactNativ = (start, end, selected) => {
	let finalStartEndIndex = {
		type: 'selectedTimeSlot',
		start: start,
		end: end,
		selected: selected
	};
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify(finalStartEndIndex));
	} catch (err) {
		console.log('cameraAccessRequest err:', err);
	}
};
export const clickOnUserSelectedRowToReactNativ = (index) => {
	let finalStartEndIndex = {
		type: 'clickOnUserselectionRow',
		index: index,
	};
	try {
		window.ReactNativeWebView.postMessage(JSON.stringify(finalStartEndIndex));
	} catch (err) {
		console.log('cameraAccessRequest err:', err);
	}
};
