import React, { useEffect, useRef, useState } from 'react';
//import TableDragSelect from "react-table-drag-select";
import TableDragSelect from '../src/components/TableDragSelect';
//import "react-table-drag-select/style.css";
import './newstyles.css';

import hours from '../src/components/Hours';
import RenderDayTimeView from './components/RenderDayTimeView';
import HighlightedDayTimeView from './components/HighlightedDayTimeView';
import { sendWebviewLoadedToReactNativ } from './components/ReactnativeHelperFunction/ReactnativeHelperFunction';
import { ColorRing } from 'react-loader-spinner';
function App() {
	
	const [curr, changeCurr] = useState(null);
	const [currCreate, changeCurrCreate] = useState(null)
	const [user, setUser] = useState(null);
	const [availableInvitee, setAvailableInvitee] = useState([]);
	const [date, setDate] = useState('');
	const [isRunOnce, setIsRunOnce] = useState(true);
	const [hideInvitySelection, setHideInvitySelection] = useState(true)
	const [isSingleCol, setIsSingleCol] = useState(true);

	useEffect(() => {
		sendWebviewLoadedToReactNativ();
	}, []);

	useEffect(() => {
		function handleEvent(userselectionArray) {
			// alert(
			// 	'react native camera access message:'+
			// 	JSON.stringify(JSON.parse(userselectionArray.data)),
			// );
			changeCurr(null);
			setUser(null);
			changeCurr(JSON.parse(userselectionArray.data).curr);
			// if (JSON.parse(userselectionArray.data).curr.length === 0){
				changeCurrCreate(JSON.parse(userselectionArray.data).currCreate);
			// } 
			setUser(JSON.parse(userselectionArray.data).user);
			setAvailableInvitee(JSON.parse(userselectionArray.data).availableInvitee);
			setDate(JSON.parse(userselectionArray.data).date);
			if (JSON.parse(userselectionArray.data)?.hideInvitySection === undefined){
				setHideInvitySelection(
					true
				);
			}else{
				setHideInvitySelection(
					JSON.parse(userselectionArray.data)?.hideInvitySection,
				);
			}
			if (JSON.parse(userselectionArray.data).isSingleCol === undefined){
				setIsSingleCol(false);
			}else{
				setIsSingleCol(JSON.parse(userselectionArray.data).isSingleCol);
			}
			
				
			// alert(JSON.parse(userselectionArray.data).currCreate);
			// setTimeout(()=>{
			// 	window.scrollTo(0, 830);
			// },1000)
		}
		document.addEventListener('userselectionArray', handleEvent);
		return () =>
			document.removeEventListener('userselectionArray', handleEvent);
	}, []);


	


	return (
		<>
			{curr !== null ? (
				<div style={{ display: 'flex' }}>
					<RenderDayTimeView type={'time'} isSingleCol={isSingleCol}/>
					{curr.length !== 0 && (
						<HighlightedDayTimeView
							type={'day'}
							curr={curr}
							changeCurr={changeCurr}
							user={user}
							setUser={setUser}
							availableInvitee={availableInvitee}
							setAvailableInvitee={setAvailableInvitee}
							date={date}
							setDate={setDate}
							isSingleCol={isSingleCol}
						/>
					)}
					{hideInvitySelection && (
						<RenderDayTimeView
							currCreate={currCreate}
							hideInvitySelection={hideInvitySelection}
							isSingleCol={isSingleCol}
							type={'day'}
							date={'Me'}
						/>
					)}
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ColorRing
						visible={true}
						height='80'
						width='80'
						ariaLabel='blocks-loading'
						wrapperStyle={{}}
						wrapperClass='blocks-wrapper'
						colors={['#51E24F', '#51E24F', '#51E24F', '#51E24F', '#51E24F']}
					/>
				</div>
			)}
		</>
	);
}

export default App;
