import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';
import { persistStore } from 'redux-persist';

export const middlewares = [ReduxThunk];

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(
	createStore,
);

export const store = createStoreWithMiddleware(rootReducer);

export const persistor = persistStore(store);
