import React, {  useEffect, useRef, useState } from 'react';
//import TableDragSelect from "react-table-drag-select";
import TableDragSelect from './TableDragSelect';
//import "react-table-drag-select/style.css";
import '../newstyles.css';

import hours from './Hours';
import TableDtagSelectDisableView from './TableDtagSelectDisableView';

function HighlightedDayTimeView(props) {
	
	const {
		type,
		curr,
		changeCurr,
		user,
		setUser,
		availableInvitee,
		setAvailableInvitee,
		isSingleCol,
		date, 
		setDate
	} = props;
	

	// useEffect(()=>{
	// 	function onlyUnique(value, index, array) {
	// 		return array.indexOf(value) === index;
	// 	}
	// 	setAllUsersLength(user.map((item) => {return item.users.split(",")}).flat().filter(onlyUnique)?.length)		
	// },[])

	

	function handleChange(cells) {
		changeCurr({ cells });

		// console.log('cells data', cells);
	}

	
	

	// console.log('====================================');
	// console.log("curr :", curr);
	// console.log('====================================');

	// function onlyUnique(value, index, array) {
	// 	return array.indexOf(value) === index;
	//   }[
	// 		  {users:"rajdeep,akshay,sanjit", },
	// 		  {users:"rajdeep1,sanjit", },
	// 		  {users:"sanjit", },
	// 		  {users:"rajdeep,akshay1,sanjit", },
	// 		  {users:"rajdeep1,akshay,sanjit2", },
	// 	  ].map((item) => {return item.users.split(",")}).flat().filter(onlyUnique)

	return (
		<>
			{curr && (
				<div>
					{type === 'day' && (
						<TableDtagSelectDisableView
							value={curr.cells}
							users={user.cells}
							allUsers={availableInvitee}
							onChange={handleChange}>
							<tr style={{ position: "relative"}}>
								<td
									disabled
									style={isSingleCol ? {
										backgroundColor: '#5C6AA8',
										color: '#fff',
										fontSize: '12px',
										width: '220px',
										position: "sticky",
										top: "0px"
									}:{
										backgroundColor: '#5C6AA8',
										color: '#fff',
										fontSize: '12px',
										width: '100px',
										position: "sticky",
										top: "0px"
									}}>
									Other Invitees
								</td>
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
						</TableDtagSelectDisableView>
					)}
				</div>
			)}
		</>
	);
}

export default HighlightedDayTimeView;
