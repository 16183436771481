import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import currDataReducer from './currDataReducer';

// import postReducer from './postReducer'


//storing cookies in local redux state

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['currDataReducer'],
};

const rootReducer = combineReducers({
	// post: postReducer,
	currDataReducer: currDataReducer,
});

export default persistReducer(persistConfig, rootReducer);
