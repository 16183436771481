export default (
	state = {
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	},
	action,
) => {
	switch (action.type) {
		case 'CURR_DATA':
			return {...state, cells:  action.payload};
		default:
			return state;
	}
};
